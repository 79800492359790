import React, { useState } from 'react';
import axios from 'axios';
import './App.css'; // Import the CSS file

function App() {
    const [inputText, setInputText] = useState('');
    const [anonymizedText, setAnonymizedText] = useState('');
    const [error, setError] = useState('');

    const handleAnonymize = async () => {
        try {
            setError('');
            const response = await axios.post('https://datmas-backend-54c1c26bb802.herokuapp.com/api/anonymize', { text: inputText });
            setAnonymizedText(response.data.anonymized_text);
        } catch (error) {
            console.error("Anonymization error:", error);
             if(error.response && error.response.data){
                setError(error.response.data.error)
            }else {
                setError("An error occurred during anonymization.");
            }
        }
    };

    return (
        <div className="container">
            <h1>Text Anonymizer</h1>
            <textarea
                className="input-textarea"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Enter text to anonymize..."
            />
            <button className="anonymize-button" onClick={handleAnonymize}>Anonymize</button>
            <textarea
                className="output-textarea"
                value={anonymizedText}
                placeholder="Anonymized text will appear here..."
                readOnly
            />
            {error && <p className="error-message">{error}</p>}
        </div>
    );
}

export default App;